<template>
	<div class="sidebar-settings-tbf scroll-updates">
		<div class="header">{{ ['software-updates', 'software-updates-show'].includes($route.name) ? $t('software_updates.title_list') : $t('software_updates.title_work_in_progress_list') }}</div>
		<div class="main-menu" v-if="loaded">
			<template v-if="softwareUpdates.length">
				<div class="item-menu" v-for="update in softwareUpdates" :key="update.id">
					<router-link :to="{name: typeUrlShow, params: { id: update.id } }">
						<div class="text" :class="{new: !update.seen && ['software-updates', 'software-updates-show'].includes($route.name)}">
							<div class="date">{{ update.created_at | moment('DD MMM YYYY') }}</div>
							<div class="name">{{ update.name }}</div>
						</div>
						<div class="arrow"><icon-arrow /></div>
					</router-link>
				</div>
			</template>
			<div class="message-empty" v-else>{{ $t('software_updates.empty_title') }}</div>
		</div>
		<div class="main-menu" v-else>
			<div class="item-menu">
				<a v-for="n in 3">
					<div class="placeholder-loader text" style="height: 16px; width: 130px;"></div>
					<div class="arrow"><icon-arrow /></div>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
    import IconArrow from '../../Icons/Arrow.vue'

	export default {
		components: {
            IconArrow
		},
		data() {
			return {
				loaded: false,
				softwareUpdates: [],
				typeUrlShow: ''
			};
		},
		watch:{
			$route (to, from){
				if(['software-updates', 'software-updates-show'].includes(from.name) && !['software-updates', 'software-updates-show'].includes(to.name)) {
					this.getSoftwareUpdates()
				} else if(['work-in-progress', 'work-in-progress-show'].includes(from.name) && !['work-in-progress', 'work-in-progress-show'].includes(to.name)) {
					this.getSoftwareUpdates()
				}
			}
		},
		async mounted() {
			await this.getSoftwareUpdates()
		},
		methods: {
			async getSoftwareUpdates() {
				let typeList = ''
				this.typeUrlShow = ''
				if(['software-updates', 'software-updates-show'].includes(this.$route.name)) {
					typeList = 'updates'
					this.typeUrlShow = 'software-updates-show'
				} else if (['work-in-progress', 'work-in-progress-show'].includes(this.$route.name)) {
					typeList = 'work_in_progress'
					this.typeUrlShow = 'work-in-progress-show'
				}

				await axios.get('/software-updates', { params: { type: typeList }})
				.then(({data}) => {
					let listData = data.data
					this.softwareUpdates = listData

					if(this.$route.name == 'software-updates' || this.$route.name == 'work-in-progress') {
						if(listData.length) {
							if(listData.find(el => !el.seen)) {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData.find(el => !el.seen).id }})
							} else {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData[0].id }})
							}
						} else {
							this.$root.$emit('showEmptyUpdates')
						}
					}
				})
				.finally(() => {
					this.loaded = true
				})
			}
		}
	};
</script>