
<template>
	<div class="modal_info confirm-close small-pad">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/warning.svg"></div>
			<div class="title">{{$t('confirm-close.title')}}</div>
		</div>
		<div class="description-modal">{{$t('sync_calendar.confirm_close_desc')}}</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelClose">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button class="btn-tbf blue center" @click="closeModal">
				<div class="loader"></div>
				<div class="text">{{ $t('confirm-close.leave') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {

			};
		},
		async mounted() {
			
		},
		methods: {
			cancelClose(){
				this.$emit("cancel_close");
			},
			closeModal(){
				this.$emit("close_modal");
			},
		}
	};
</script>