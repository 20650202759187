<template>
  <draggable class="dragArea" :list="departments" :group="{ name: 'g1' }">
    <div v-for="el in departments" :key="el.name">
      <div class="department-item">{{ el.name }}</div>
      <nested-draggable-departments :departments="el.departments" :class="{empty: !el.departments.length}" />
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: {
    departments: {
      required: true,
      type: Array
    }
  },
  components: {
    draggable
  },
  name: "nested-draggable-departments"
};
</script>