import axios from 'axios'
import Vue from 'vue'
import dataJson from '../libraries/help_links.json'

export default {
    namespaced: true,

    state() {
        return {
            data: dataJson
        };
    },
    getters: {
    	allLinks(state) { 
    		return state.data
    	},
        currentLink: (state) => (routeName) => {
            var linkName = state.data.find( el => 
                el.routes.split(',').includes(routeName)
            )

            return linkName ? linkName : false 

        }
    }
}