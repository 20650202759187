<template>
	<div class="modal_info">
		<div class="header-submodal">
			<div class="title">{{ $t('subscription.title-upgrade-confirmation') }}</div>
		</div>
		<div class="description-modal">
			{{ $t('subscription.question-upgrade-confirmation') }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelChange">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="btnChangePlan" class="btn-tbf blue center" @click="submitChangePlan">
				<div class="loader"></div>
				<div class="text">{{ $t('general.update') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {
				
			};
		},
		props: {
			data: Object
		},
		mounted() {

		},
		methods: {
			cancelChange(){
				this.$emit("close_modal");
			},
			submitChangePlan(){
				var buttonName = `btnChangePlan`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.post('/change-plan-account', {
					package_token: this.data.packageData.token
				}).then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							this.$root.$emit('changePlanAccount')
						}, 1000)
					}, 300)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(()=>{
						this.$emit("close_modal")
					}, 1000)
				})
			}
		}
	};
</script>