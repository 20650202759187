<template>
	<div class="modal_info modal_show_day_off">
		<template v-if="loaded">
			<div class="header-submodal with-btn">
				<div class="title">{{ day_off.name }}</div>
				<div class="button-close">
					<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></div>
				</div>
			</div>

			<div class="subheader-data">
				<div class="item" v-if="day_off.start_date == day_off.end_date">
					{{ $t('general.date') }}: {{ day_off.start_date | moment('DD MMMM YYYY') }}
				</div>
				<div class="item" v-else>
					{{ $t('general.date') }}: {{ day_off.start_date | moment('DD MMMM YYYY') }} - {{ day_off.end_date | moment('DD MMMM YYYY') }}
				</div>
				<div class="item">
					{{ $t('days_off.type') }}: {{ $t(`days_off.options_type.${day_off.type}`) }}
				</div>
				<div class="item" v-if="day_off.users.length">
					{{ $t('general.users') }}: {{ day_off.users.map(el => {return el.name}).join(", ") }}
				</div>
			</div>

			<div class="button-actions">
				<button class="btn-tbf grey center" @click="$root.$emit('change_modal', 'crud_day_off', {crud: 'edit', id: data.id, from: 'calendar'})">
					<div class="text">{{ $t('general.edit') }}</div>
				</button>
				<button class="btn-tbf red center" @click="$root.$emit('change_modal', 'delete', {type: 'vacation_day', from: 'index_vacation_days_calendar', model: day_off})">
					<div class="text">{{ $t('general.delete') }}</div>
				</button>
			</div>
		</template>
		<loader-show-responsibility v-else />
	</div>
</template>

<script type="text/javascript">
	import IconProgress from '../Icons/Progress'
	import IconClose from '../Icons/Close'
	import LoaderShowResponsibility from '@/components/PagesLoaders/ShowResponsibility'

	export default {
		data() {
			return {
				loaded: false,
				value: '',
				valueDebounce: '',
				complete: '',
				why_not: '',
				free_day: false,
				responsibility: {},
				day_off: {}
			};
		},
		props: {
            data: Object
        },
        components: {
        	IconProgress,
        	IconClose,
        	LoaderShowResponsibility
        },
		mounted() {
			this.getDataVacationDay()
		},
		methods: {
			getDataVacationDay() {
				axios.get(`/vacation-days/${this.data.id}/edit`)
				.then(({data}) => {
					this.day_off = data.data
				})
				.finally(() => {
					this.loaded = true
				})
			},
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>