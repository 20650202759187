<template>
<div class="modal-tbf modal-vacant-job">
    <div class="container-modal opacity-page">
    	<template v-if="loaded">
	        <div class="header-modal">
	            <div class="title">
	                {{ vacantJob.name }}
	            </div>
	            <div class="actions">
	                <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
	            </div>
	        </div>

	        <div class="info-content">
	            <div class="container-content-modal">
	                <div class="description" v-if="vacantJob.description">{{ vacantJob.description }}</div>

	                <div class="main-information">
		                <div v-if="vacantJob.roles.length">
			                <div class="label">{{ $t('general.roles') }}</div>
			                <div class="value">{{ vacantJob.roles.map(el => { return el.name }).join(", ") }}</div>
		                </div>
		                <div v-if="vacantJob.departments.length">
			                <div class="label">{{ $t('general.departments') }}</div>
			                <div class="value">{{ vacantJob.departments.map(el => { return el.name }).join(", ") }}</div>
		                </div>
		                <div v-if="vacantJob.workstation">
			                <div class="label">{{ $t('general.workstation') }}</div>
			                <div class="value">{{ vacantJob.workstation.name }}</div>
		                </div>
	                </div>

	                <div class="info-tabel info-work-schedule" v-if="vacantJob.work_schedule">
	                	<div class="title-template"><span class="label-section">{{ $t('create-user.work-schedule') }} - </span>{{ vacantJob.work_schedule.name }}</div>
	                	<div class="intervals">
	                		<div class="interval">
	                			<div class="box">
	                				<div class="day">{{ $t('general.monday') }}</div>
	                				<div class="value" v-if="vacantJob.work_schedule.schedules.find(el => el.day == 'monday')">{{ vacantJob.work_schedule.schedules.find(el => el.day == 'monday').start_time + ' - ' + vacantJob.work_schedule.schedules.find(el => el.day == 'monday').end_time }}</div>
	                				<div class="value" v-else>{{ $t('general.free') }}</div>
	                			</div>
	                		</div>
	                		<div class="interval">
	                			<div class="box">
	                				<div class="day">{{ $t('general.tuesday') }}</div>
	                				<div class="value" v-if="vacantJob.work_schedule.schedules.find(el => el.day == 'tuesday')">{{ vacantJob.work_schedule.schedules.find(el => el.day == 'tuesday').start_time + ' - ' + vacantJob.work_schedule.schedules.find(el => el.day == 'tuesday').end_time }}</div>
	                				<div class="value" v-else>{{ $t('general.free') }}</div>
	                			</div>
	                		</div>
	                		<div class="interval">
	                			<div class="box">
	                				<div class="day">{{ $t('general.wednesday') }}</div>
	                				<div class="value" v-if="vacantJob.work_schedule.schedules.find(el => el.day == 'wednesday')">{{ vacantJob.work_schedule.schedules.find(el => el.day == 'wednesday').start_time + ' - ' + vacantJob.work_schedule.schedules.find(el => el.day == 'wednesday').end_time }}</div>
	                				<div class="value" v-else>{{ $t('general.free') }}</div>
	                			</div>
	                		</div>
	                		<div class="interval">
	                			<div class="box">
	                				<div class="day">{{ $t('general.thursday') }}</div>
	                				<div class="value" v-if="vacantJob.work_schedule.schedules.find(el => el.day == 'thursday')">{{ vacantJob.work_schedule.schedules.find(el => el.day == 'thursday').start_time + ' - ' + vacantJob.work_schedule.schedules.find(el => el.day == 'thursday').end_time }}</div>
	                				<div class="value" v-else>{{ $t('general.free') }}</div>
	                			</div>
	                		</div>
	                		<div class="interval">
	                			<div class="box">
	                				<div class="day">{{ $t('general.friday') }}</div>
	                				<div class="value" v-if="vacantJob.work_schedule.schedules.find(el => el.day == 'friday')">{{ vacantJob.work_schedule.schedules.find(el => el.day == 'friday').start_time + ' - ' + vacantJob.work_schedule.schedules.find(el => el.day == 'friday').end_time }}</div>
	                				<div class="value" v-else>{{ $t('general.free') }}</div>
	                			</div>
	                		</div>
	                		<div class="interval">
	                			<div class="box">
	                				<div class="day">{{ $t('general.saturday') }}</div>
	                				<div class="value" v-if="vacantJob.work_schedule.schedules.find(el => el.day == 'saturday')">{{ vacantJob.work_schedule.schedules.find(el => el.day == 'saturday').start_time + ' - ' + vacantJob.work_schedule.schedules.find(el => el.day == 'saturday').end_time }}</div>
	                				<div class="value" v-else>{{ $t('general.free') }}</div>
	                			</div>
	                		</div>
	                		<div class="interval">
	                			<div class="box">
	                				<div class="day">{{ $t('general.sunday') }}</div>
	                				<div class="value" v-if="vacantJob.work_schedule.schedules.find(el => el.day == 'sunday')">{{ vacantJob.work_schedule.schedules.find(el => el.day == 'sunday').start_time + ' - ' + vacantJob.work_schedule.schedules.find(el => el.day == 'sunday').end_time }}</div>
	                				<div class="value" v-else>{{ $t('general.free') }}</div>
	                			</div>
	                		</div>
	                	</div>
	                </div>

	                <div class="info-tabel info-foreign-languages" v-if="vacantJob.foreign_languages.length">
	                	<div class="label-section">{{ $t('create-user.languages') }}</div>
	                	<div class="tabel">
		                	<div class="row-header">
		                		<div class="column-name">{{ $t('general.name') }}</div>
		                		<div class="column-listening">{{ $t('create-user.listening') }}</div>
		                		<div class="column-reading">{{ $t('create-user.reading') }}</div>
		                		<div class="column-spoke-interaction">{{ $t('create-user.spoke-interaction') }}</div>
		                		<div class="column-spoke-production">{{ $t('create-user.spoke-production') }}</div>
		                		<div class="column-writing">{{ $t('create-user.writing') }}</div>
		                	</div>

		                	<div class="row-item" v-for="languageItem in vacantJob.foreign_languages">
		                		<div class="column-name">{{ languageItem.name }}</div>
		                		<div class="column-listening">{{ languageItem.levels.find(el => el.type == 'listening').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'listening').level }}</div>
		                		<div class="column-reading">{{ languageItem.levels.find(el => el.type == 'reading').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'reading').level }}</div>
		                		<div class="column-spoke-interaction">{{ languageItem.levels.find(el => el.type == 'spoke_interaction').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'spoke_interaction').level }}</div>
		                		<div class="column-spoke-production">{{ languageItem.levels.find(el => el.type == 'spoke_production').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'spoke_production').level }}</div>
		                		<div class="column-writing">{{ languageItem.levels.find(el => el.type == 'writing').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'writing').level }}</div>
		                	</div>
	                	</div>
	                </div>

	                <div class="info-tabel info-skills" v-if="vacantJob.skills.length">
	                	<div class="label-section">{{ $t('create-user.skills') }}</div>
	                	<div class="tabel">
	                		<div class="row-header">
	                			<div class="column-name">{{ $t('general.name') }}</div>
	                			<div class="column-level">{{ $t('create-user.level') }}</div>
	                		</div>

	                		<div class="row-item" v-for="skillItem in vacantJob.skills">
	                			<div class="column-name">{{ skillItem.name }}</div>
	                			<div class="column-level">{{ skillItem.level ? $t(`skill.type.${skillItem.level}`) : $t('evaluation.not_completed') }}</div>
	                		</div>
	                	</div>
	                </div>

	            </div>
	        </div>
    	</template>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'

	export default {
		props: {
			data: Object
		},
		data() {
			return {
				vacantJob: {},
				loaded: false
			};
		},
        components: {
            IconClose
        },
		async mounted() {
			await this.getVacantJobData(this.data.slug)
		},
		methods: {
			async getVacantJobData(slug) {
				await axios.get(`/vacant-jobs/${slug}/show`)
				.then(({data}) => {
					this.vacantJob = data.data
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			closeModal() {
				this.$emit("close_modal");
			}
		}
	};
</script>