import Vue  from 'vue';
import Vuex from 'vuex';

import auth from './auth.js';
import applications from './applications.js';
import help_links from './help_links.js';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        auth,
        applications,
        help_links
    },
  
    strict: debug
});