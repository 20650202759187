<template>
    <div class="departments-order-group">
      <template v-for="el in departments">
        <div class="department-item">{{ el.name }}</div>
        <departments-order :departments="el.departments" :class="{empty: !el.departments.length}" />
      </template>
    </div>
</template>

<script>
export default {
  props: {
    departments: {
      required: true,
      type: Array
    }
  },
  name: "departments-order"
};
</script>